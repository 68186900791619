<template>
    <div>
        <breadcrumbs title="register"/>

        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-6 m-auto">
                        <div v-if="errors" class="alert alert-danger" role="alert">
                            <p>{{errors.message}}</p>
                            <ul v-if="errors.errors">
                                <li v-for="(item, index) in errors.errors" :key="index">
                                    {{item.join(',')}}
                                </li>
                            </ul>
                        </div>
                        <form autocomplete="off" @submit="submit">
                            <div class="form-group">
                                <label>Nama</label>
                                <input v-model="register.name" type="text" class="form-control" placeholder="Nama">
                            </div>
                            <div class="form-group">
                                <label>Email</label>
                                <input v-model="register.email" type="email" class="form-control" placeholder="E-mail">
                            </div>
                            <div class="form-group">
                                <label>Username</label>
                                <input v-model="register.username" type="text" class="form-control"
                                       placeholder="User name">
                            </div>
                            <div class="form-group">
                                <label>Password</label>
                                <input v-model="register.password" type="password" class="form-control"
                                       placeholder="Password">
                            </div>
                            <div class="form-group text-center mt-5">
                                <button type="submit" class="btn btn-primary btn-block">Register</button>
                                <router-link to="/login" class="btn btn-info btn-block">Login</router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Register",
        components: {
            breadcrumbs: () => import('@/components/layout/Breadcrumb'),
        },
        data() {
            return {
                register: {
                    username: '',
                    password: '',
                    name: '',
                    email: '',
                },
                errors: null,
            }
        },
        methods: {
            submit(e) {
                e.preventDefault();
                this.errors = null;
                this.$store.dispatch('registerAction', {...this.register}).then(() => {
                    if (localStorage.getItem('current-section') !== null) {
                        const currentSection = JSON.parse(localStorage.getItem('current-section'));
                        this.$router.push({name: 'section', params: {sectionId: currentSection.id}});
                    } else {
                        this.$router.push({name: '404'});
                    }
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data;
                            break;
                        default:
                            console.log(error);
                            break;
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
